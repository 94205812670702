@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-Container {
  width: 85%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .App-Container {
    width: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  margin-top: 8px;
  margin-bottom: 8px;
  border-color: #777;
}

p {
  font-size: 1.2rem;
  margin-top: 1.2rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1 {
  font-size: 1.7rem;
  margin-top: 25px;
  font-weight: bold;
}

h2 {
  font-size: 1.4rem;
  margin-top: 25px;
  font-weight: bold;
}

h3 {
  font-size: 1.1rem;
  margin-top: 25px;
  font-weight: bold;
}

h4 {
  font-weight: bold;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

a {
  color: blue;
  text-decoration: underline;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.saving {
  transition: opacity 0.25s;
  opacity: 1;
  position: fixed;
  top: 5px;
  left: 50%;
  background-color: #ffffffcc;
  border-radius: 3px;
  padding: 5px;
  z-index: 10000;
  pointer-events: none;
}

.saving.hide {
  opacity: 0;
}

.center {
  text-align: "center";
}

.text-ellipsis-middle {
  display: inline-flex;
  flex-wrap: nowrap;
  max-width: 100%;
}

.text-ellipsis-middle > div:first-child {
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-ellipsis-middle > div + div {
  flex: 1 0 auto;
  white-space: nowrap;
}

.numberPage .npn {
  display: inline-block;
  width: 49vw;
  height: 62vw;
  padding: 25px;
  /* writing-mode: vertical-rl; */
}

@media print {
  .userInfo {
    page-break-after: always;
  }

  .numberPage {
    page-break-before: always;
    page-break-after: always;
    position: relative;
    left: -65px;
    padding-top: 45px;
    width: 100vw !important;
    height: 100vh !important;
    margin: 0 !important;
  }

  .noPrint {
    display: none !important;
  }

  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }

  .section {
    page-break-inside: avoid;
    page-break-after: always;
  }
}

table.eval th.rotated {
  height: 120px;
  white-space: nowrap;
  padding: 0;
  width: 20px;
}

table.eval th.rotated div {
  transform: translate(0px, 30px) rotate(270deg);
  width: 30px;
}

table .total {
  /* background-color: #ddd !important; */
  text-align: right !important;
}

table .grand-total {
  /* background-color: #e74302 !important; */
  /* font-weight: bold; */
  text-align: right !important;
}

table.eval thead tr:nth-child(2) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}
table.eval tr {
  height: 30px;
}
table.eval td {
  padding: 10px;
  text-align: left;
}

table.eval td:last-child {
  padding-right: 10px;
}

table.eval td.userNumber {
  text-align: center;
  font-family: Courier;
  font-weight: bold;
}

table.eval td.number {
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.tabular-numeric {
  font-variant-numeric: tabular-nums;
}

.decimalPortion {
  opacity: 0.3;
}

table.eval tr.judge td .decimalPortion {
  visibility: hidden;
}

table.eval td {
  white-space: nowrap;
}

table.eval td.judgeId {
  white-space: nowrap;
  padding-left: 20px !important;
  max-width: 50px;
  overflow: hidden;
  color: #aaa;
}

table.eval tr td {
  border-top: 1px solid rgb(224, 224, 224) !important;
  border-bottom: 0px solid rgb(224, 224, 224) !important;
}

table.eval tr.judge td {
  color: #aaa;
  border-top-width: 0px !important;
}

table.eval tr:last-child {
  border-bottom: 1px solid rgb(224, 224, 224) !important;
}

table.eval tr td.has-comments {
  border-top: 5px solid #999 !important;
}

.stunt-grid {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  border: 1px dotted #606972;
  color: #ddd;
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
}

.stunt-grid div {
  height: 40px;
  text-align: center;
  padding-top: 5px;
}

.stunt-grid div.experience {
  color: rgb(96, 105, 114);
  font-weight: bold;
}

.stunt-grid div.best {
  background-color: rgb(96, 105, 114);
  color: white;
  border-radius: 10px;
}

.stunt-grid div.favorite::after {
  content: " * ";
}

.user-number {
  background-color: #606972;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 2em;
  font-family: monospace;
  color: white;
}

table.eval th.fixed-col {
  z-index: 12 !important;
}

table.eval .fixed-col {
  position: sticky;
  background: rgb(250, 250, 250);
  left: 0;
  z-index: 11 !important;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.scorebox {
  height: 30px;
  width: 30px;
  border: 1px solid #606972;
  border-radius: 10px;
  display: inline-block;
  min-width: 30px;
}

.scorelabel {
  font-size: 1.1em;
  padding-left: 5px;
}

.text-right {
  text-align: right;
}

.pb-2 {
  padding-bottom: 0.5em;
}

.summer-block {
  border: 1px solid #eee;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
}

.summer-block-text {
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.mt-4 {
  margin-top: 1em;
}

.tumbling-table {
  border-spacing: 4px;
  border-collapse: separate;
}
.tumbling-table th {
  @apply text-left px-2;
}

.tumbling-table td {
  @apply px-2;
}
.tumbling-table td.box {
  @apply border border-gray-200 mb-2 rounded-md w-[40px];
}

.tumbling-table tr {
}

.tumbling-cell-0 {
}

.tumbling-cell-1 {
  background-color: rgba(96, 105, 114, 0.333);
}

.tumbling-cell-2 {
  background-color: rgba(96, 105, 114, 0.667);
  color: white;
}

.tumbling-cell-3 {
  background-color: rgb(96, 105, 114);
  color: white;
}

.tumbling-cell-4 {
  background-color: rgb(96, 105, 114);
  color: white;
}

.tumbling-cell {
  @apply text-center rounded-md w-[40px];
}
